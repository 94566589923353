import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Link from "../components/LinkWithTransitionLock"
import Helmet from "react-helmet"
import InViewObserver from "../components/InViewObserver"
import PageContentPath from "../components/PageContentPath"

import { isMobile } from "react-device-detect"
import { Expo, gsap } from 'gsap';
import mediumZoom from 'medium-zoom'

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap project_c_4_wrap">

        <div className="page_header">
          <h2 className="sub_title">駐村編輯</h2>
          <h1 className="main_title">食物記憶走讀</h1>
          <h6 className="speaker">策展人/林書豪 導覽人/陳冠蓉</h6>
        </div>

        <div className="page_content project_c_4_2">

          <section className="row_area_s project_c_4_2_a">
            <div className="column_4">
              <div className="context">
                <p>藝術家汲取進駐新富町市場的創作經驗，帶領大家從地方飲食和文字書寫，關心在地的味道和記憶。</p>
                {/* <p className="tag"><i>#萬華老故事 #文化保存 #新舊發展</i></p> */}
                <p><strong>路線</strong> - <i>新富町市場→在在 Zoizoi →濟善老麵 →咕嚕嚕木耳飲→雙和市場→越窩越好越式料理→台灣社區實踐協會→越南食品行→菓實日→MounGar423→CURA PIZZA →家吶子 台式居酒屋</i></p>
              </div>
            </div>
          </section>


          <section className="row_area_s project_c_4_2_b project_c_4_head row_break">
            <div className="column_5 onset_left_1">
              <figure className="figure size_full main_map">
                <img src={require("../images/project_c/c_4_2/project_c_4_2_map.jpg")} alt="食物記憶走讀"/>
              </figure>
            </div>
            <div className="column_2 leader">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_2/project_c_4_2_lead.jpg")} alt="食物記憶走讀"/>
                <figcaption className="figcaption right">陳冠蓉<br/>藝術家</figcaption>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_c_4_3_c row_gallery">
            <div className="column_6 row_area_s">
              <figure className="figure size_full video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/VckbKfkoawo" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <div className="video_title">影像創意/黑鳥音像 BlackBird Imagine Studio</div>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_c_4_2_c row_gallery">
            <div className="column_5 onset_left_2 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_2/project_c_4_2_3.jpg")} alt="咕嚕嚕木耳飲"/>
              </figure>
              <div className="column_6">
                <div className="context_s">
                  <p>在大馬路旁的連棟店家之一「咕嚕嚕木耳飲」，很容易就會錯過它。其中販售的木耳飲，一口喝下清涼消暑。不敢喝木耳，也有寒天檸檬可以選擇。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_2_d row_gallery">
            <div className="column_4 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_2/project_c_4_2_5.jpg")} alt="家吶子"/>
              </figure>
              <div className="column_6 onset_left_2">
                <div className="context_s">
                  <p>「家吶子」是青年開設的台日居酒屋，除了好吃新穎的菜色可供選擇，主理人也致力於推廣南萬華的文化與故事，是在地人時常歡聚的所在。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_2_e row_gallery">
            <div className="column_5 row_area_s onset_left_3">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_2/project_c_4_2_6.jpg")} data-zoomable alt="越窩越好"/>
              </figure>
              <div className="column_6 onset_left_2">
                <div className="context_s">
                  <p>「越窩越好」的越式料理專賣，是社團法人台灣社區實踐協會與社區新住民媽媽們合作展開的計畫，它不僅是社區的一間越南小吃店，更是社區網絡凝聚、姊妹互相學習的據點。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_2_f row_break">
            <div className="column_3">
              <div className="context">
                <h5>生活飲食故事工作坊</h5>
                <p>透過文字書寫，點出在地「食物、美食、料理」與自己的情感關係和生命記憶。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_2_g">
            <div className="column_3 onset_left_4 offset_right_1 row_area_s">
              <figure className="figure size_full">
                <img src={require("../images/project_c/c_4_2/project_c_4_2_7.jpg")} alt="生活飲食故事工作坊"/>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_c_4_2_h">
            <div className="column_4 onset_left_1 row_area_s">
              <figure className="figure size_full with_title">
                <img src={require("../images/project_c/c_4_2/project_c_4_2_9.jpg")} alt="咖哩飯"/>
                <figcaption className="figcaption main left"><div className="text_inner">咖哩飯</div></figcaption>
              </figure>
              <div className="column_8">
                <div className="context_s right">
                  <p>食物擁有放空與自我對話的時間與空間。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_2_i row_gallery">
            <div className="column_4 row_area_s">
              <figure className="figure size_full with_title">
                <img src={require("../images/project_c/c_4_2/project_c_4_2_10.jpg")} alt="胡椒餅、水煎包"/>
                <figcaption className="figcaption main center"><div className="text_inner">胡椒餅、水煎包</div></figcaption>
              </figure>
              <div className="column_8">
                <div className="context_s right">
                  <p>小時候的記憶總是能記得好久好久。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_c_4_2_j">
            <div className="column_4 onset_left_2 row_area_s">
              <figure className="figure size_full with_title">
                <img src={require("../images/project_c/c_4_2/project_c_4_2_11.jpg")} alt="牛肉麵"/>
                <figcaption className="figcaption main center"><div className="text_inner">牛肉麵</div></figcaption>
              </figure>
              <div className="column_8">
                <div className="context_s right">
                  <p>一段旅行的深刻記憶，與食物連結在一起。</p>
                </div>
              </div>
            </div>
          </section>

          {!isMobile ? <PageContentPath /> : null}

        </div>


        <div className="page_footer">
          <Link to="/project_c_4_3" className="next_page">
            <div className="next_title">社區藝術走讀</div>
            <div className="next_owner">策展人/林書豪 導覽人/魏子鈞</div>
          </Link>
        </div>

      </div>

    );
  }

}


export default Page
